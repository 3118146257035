import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const CleanOutPage = () => {
  return (
    <Layout pageTitle="Clean Out">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../media/pump_station_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Clean-out</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>WE SUCK. WE'VE BEEN DOING IT FOR OVER 20 YEARS</h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <StaticImage
                src="../../media/vac-truck.jpg"
                alt="white and green vac truck"
              />
            </Col>
            <Col>
              <p>
                Regardless of how small or large a tank or lagoon is, proper cleaning is required to ensure maximum efficiency. In addition to having specialized equipment to do the job, our crews are trained to enter confined spaces and get it done. Tank entry can be dangerous and the presence of hazardous gases can be deadly without the proper training and PPE.
              </p>
              <p>
                Regular tank cleaning is the only way to ensure you get the best performance out of your system. The removal of solids buildup on a maintenance schedule, will keep your tanks doing what they should.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>DIGESTERS</h3>
              <p>
                Regardless how advanced your digester is, it will need to be cleaned. Solids build up and the efficiency levels drop. We recommend regular cleaning of your digester from top to bottom every 4 to 7 years. This is also an ideal time to inspect your digester system and tank to ensure everything is in proper working order.
              </p>
              <p>
                Wessuc brings a high level of expertise, extensive safety training, and equipment that is designed to get the job done.  From inspection to pumping, vacuuming, to dewatering, and more. Using high powered vacuum trucks as well as mobile screening units to bring your digester system back to peak performance.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/clean_out_digesters.jpg"
                alt="Wessuc team works safely near the opening of a digester" />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row lg={2} xs={1}>
            <Col lg={{ order: 'first' }} xs={{ order: 'last' }}>
              <video controls id="lagoon-crawler-video" width="100%">
                <source src="https://wordpress.wessuc.com/wp-content/uploads/2022/01/Untitled-design.mp4" type="video/mp4" />
              </video>
            </Col>
            <Col>
              <h3>Screen &amp; Haul</h3>
              <p>
                Our custom screening units are mobile and built to filter out the inorganic materials such as; plastics, hair and so on. What's left is a pure product that, depending on NASM approval, can be land applied to nearby farmers' fields as part of our beneficial reuse process. 
              </p>
              <p>
                What you see to the left in our video is a set up of a screen and haul process. Turn your waste into something valuable to the farming community.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>PUMPING STATIONS</h3>
              <p>
                Pumping stations are used to move wastewater and sewage from lower areas of land or where the depth of a pipeline is farther underground than usual.  Like any equipment or machinery, regular maintenance and cleaning is required to prevent a drop in performance or an eventual breakdown.
              </p>
              <p>
                Our team at Wessuc is trained to keep pumping stations operating and ensure that wastewater is moving properly to its treatment facility. Through an ongoing maintenance schedule, we will use our expertise and specialized equipment to optimize pump station performance.
              </p>
              <p>
                Should a pipe break, a blockage occur, or other emergency situations arise, Wessuc can be called in to quickly handle it. We will keep things moving until repairs are finished and the pumping station is back online.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../media/clean_out_pumping_stations.jpg"
                alt="Wessuc employees smile from inside a pump station they're cleaning" />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col>
              <h3>LAGOON CLEANING</h3>
              <p>
                Lagoon clean outs occur for regular maintenance of a facility.
              </p>
              <p>
                The requirement for regular cleaning will vary depending on the influent characteristics, lagoon size, and effluent criteria imposed. A range of anywhere from five to twenty-five years before a regular clean out is required.
              </p>
              <p>
                Lagoon cleaning can be done in a number of ways depending on the purpose of the cleaning and the design characteristics of the lagoon itself. Polypropylene lined lagoons require additional care to ensure the liner is not damaged during the cleaning process.
              </p>
              <p>
                To the right you can see an example of a lagoon crawler agitating sludge in a lagoon, towards our pump-line, which is later hauled to nearby farmer's field for land application.
              </p>
              <p>
                Wessuc has extensive experience cleaning lagoons. Whether they are clay lined or have a synthetic liner, Wessuc has the required resources to complete each project. Lagoon cleaning may be combined with our dewatering operation to provide temporary storage and additional management options.
              </p>
            </Col>
            <Col>
              <video controls id="lagoon-crawler-video" width="100%">
                <source src="https://wordpress.wessuc.com/wp-content/uploads/2022/01/Lagoon-Crawler.mp4" type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default CleanOutPage